<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="i in news"
            :key="i.id">
            <article class="tw-flex tw-flex-col">
                <img
                    alt=""
                    class="tw-aspect-[2/1] tw-rounded-3xl tw-object-cover tw-object-center"
                    :src="useMedia(i.image).small" />
                <h4 class="tw-mt-3 tw-text-lg tw-font-semibold lg:tw-text-xl xl:tw-mt-6 xl:tw-text-2xl">{{ i.title }}</h4>
                <div class="lg:tw-text-lg xl:tw-mt-3 xl:tw-text-xl">
                    {{ i.description }}
                </div>
                <v-btn
                    class="tw-mt-3 tw-h-10 tw-px-4 max-md:tw-ms-auto md:tw-me-auto xl:tw-mt-6"
                    color="blue"
                    rounded="pill"
                    >
                    <a :href="i.url" target="_blank">Ler mais</a>
                    </v-btn
                >
            </article>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { useMedia } from '../composables/useMedia';

    const props = defineProps<{
        news: any[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 3,
        perMove: 1,
        gap: '4rem',
        pagination: false,
        arrows: true,
        autoplay: true,
        interval: 5000,
        breakpoints: {
            1200: {
                gap: '3rem',
            },
            640: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped></style>
