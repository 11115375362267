<template>
    <template v-if="page">
        <div
            class="min-h-dvh tw-flex tw-flex-col tw-items-center tw-justify-center"
            :style="bgStyle">
            <Tag
                :color="page.color"
                :text="page.name" />
            <h1
                class="tw-mt-7 tw-w-11/12 tw-text-center xl:tw-w-10/12 2xl:tw-w-8/12"
                v-html="page.bannerTitle"></h1>
            <div
                v-if="page.bannerButtons && page.bannerButtons.length"
                class="tw-mt-16 tw-flex tw-flex-col tw-items-center tw-gap-6 md:tw-flex-row">
                <v-btn
                    v-for="btn in page.bannerButtons"
                    :key="btn.id"
                    class="banner-btn tw-px-7 xl:tw-text-base 3xl:tw-text-lg"
                    :color="page.color"
                    elevation="0"
                    height="57"
                    rounded="pill"
                    :variant="btn.type">
                    <a
                        :href="btn.url"
                        target="_blank"
                        >{{ btn.text }}</a
                    >
                </v-btn>
            </div>
        </div>
        <div class="io-container tw-relative tw-z-20 tw-grid tw-grid-cols-1 tw-gap-4 tw-overflow-hidden tw-pt-8 max-xl:tw-gap-16 xl:tw-grid-cols-12 3xl:tw-pt-16">
            <div class="tw-relative tw-z-30 tw-order-1 xl:tw-col-span-6">
                <img
                    alt=""
                    class="tw-relative tw-left-1/2 tw-my-8 tw-max-h-96 tw-max-w-[calc(100%+100px)] -tw-translate-x-1/2 md:tw-hidden"
                    src="@/assets/images/auth/login.jpg" />
                <h1
                    class="tw-mt-6"
                    v-html="page.contentTitle"></h1>
                <div
                    class="h3 tw-mt-3 md:tw-mt-6 lg:tw-mt-8"
                    v-html="page.contentDescription"></div>
            </div>
            <div class="tw-order-2 max-md:tw-hidden xl:tw-col-span-6">
                <img
                    alt=""
                    class="desktop-image tw-relative tw-z-10 tw-mx-auto tw-h-full max-xl:tw-max-h-96 max-md:tw-hidden"
                    :src="useMedia(page.contentImage).original" />
            </div>
        </div>
        <div
            v-if="hasFeatures"
            class="io-container">
            <h2
                v-if="page.featuresTitle"
                class="tw-text-center"
                v-html="page.featuresTitle"></h2>
            <h2
                v-else
                class="tw-text-center">
                Funcionalidades <span class="tw-text-blue">gerais</span>
            </h2>
            <h3
                v-if="page.featuresText"
                class="tw-text-center"
                v-html="page.featuresText"></h3>

            <div
                v-if="page.features && page.features.length"
                class="tw-mt-10 tw-grid tw-grow tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-mt-14 lg:tw-gap-8 xl:tw-grid-cols-3 3xl:tw-gap-16">
                <div
                    v-for="feature in page.features"
                    :key="feature.id"
                    class="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-center tw-rounded-4xl tw-bg-opacity-10 tw-px-8 tw-py-10 lg:tw-px-12 lg:tw-py-16"
                    :class="feature.color ? `tw-bg-${feature.color}` : ''">
                    <img
                        class="tw-w-12 3xl:tw-w-16"
                        :src="useMedia(feature.icon).small" />
                    <h4 class="tw-mt-6 tw-text-2xl tw-font-semibold 3xl:tw-mt-9 3xl:tw-text-3xl">{{ feature.title }}</h4>
                    <p class="tw-mt-2 tw-text-lg tw-font-light 3xl:tw-mt-4 3xl:tw-text-2xl">
                        {{ feature.text }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="hasCharacteristics"
            class="io-container tw-relative tw-z-20 tw-grid tw-grid-cols-1 tw-gap-16 tw-overflow-hidden tw-pt-8 lg:tw-gap-8 lg:tw-pt-16 xl:tw-grid-cols-12 2xl:tw-gap-20">
            <div class="max-md:tw-hidden xl:tw-col-span-5">
                <img
                    alt=""
                    class="desktop-image tw-relative tw-z-10 tw-mx-auto tw-h-full max-xl:tw-max-h-96 max-md:tw-hidden"
                    :src="useMedia(page.characteristicsImage).original" />
            </div>
            <div class="tw-relative tw-z-30 xl:tw-col-span-7">
                <img
                    alt=""
                    class="tw-relative tw-left-1/2 tw-my-8 tw-max-h-96 tw-max-w-[calc(100%+100px)] -tw-translate-x-1/2 md:tw-hidden"
                    :src="useMedia(page.characteristicsImage).original" />
                <h2
                    v-if="page.characteristicsTitle"
                    class="tw-mb-0 max-md:tw-mt-6"
                    v-html="page.characteristicsTitle"></h2>
                <h2
                    v-else
                    class="tw-mb-0 max-md:tw-mt-6">
                    Características <span class="tw-text-blue">gerais</span>
                </h2>
                <div
                    v-if="page.characteristicsText"
                    class="h3 tw-mt-3 md:tw-mt-6 lg:tw-mt-10"
                    v-html="page.characteristicsText"></div>
                <div
                    v-if="page.characteristics && page.characteristics.length"
                    class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-10 md:tw-grid-cols-2">
                    <div
                        v-for="(carac, i) in page.characteristics"
                        :key="carac.id"
                        class="tw-flex tw-gap-5"
                        :class="i % 2 === 0 ? 'tw-flex-row' : 'max-md:tw-flex-row-reverse'">
                        <img
                            alt=""
                            class="tw-h-16 tw-w-16 tw-object-contain"
                            :src="useMedia(carac.icon).small" />
                        <div>
                            <h5 class="tw-text-lg tw-text-blue-400">{{ carac.title }}</h5>
                            <p class="tw-text-xl tw-font-medium">{{ carac.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="page.news && page.news.length"
            class="io-container">
            <h2
                v-if="page.newsTitle"
                v-html="page.newsTitle"></h2>
            <h2 v-else>Noticias</h2>

            <NewsSlider :news="page.news" />
        </div>
        <div
            v-if="page.partners && page.partners.length"
            class="io-container">
            <h2
                v-if="page.financedByTitle"
                class="tw-text-center"
                v-html="page.financedByTitle"></h2>
            <h2
                v-else
                class="tw-text-center">
                Financiado <span class="tw-text-blue">por</span>
            </h2>
            <PartnersSlider :partners="page.partners" />
        </div>
        <div class="bottom-banner tw-py-20">
            <div class="io-container tw-relative tw-my-0">
                <h2
                    class="max-md:tw-text-2xl sm:tw-w-8/12"
                    v-html="page.bottomBannerTitle"></h2>
                <div class="tw-flex tw-gap-6">
                    <v-btn
                        class="tw-h-12 3xl:tw-h-16 3xl:tw-w-52 3xl:tw-text-lg"
                        color="blue"
                        rounded="pill"
                        >Contacte-nos</v-btn
                    >
                    <v-btn
                        v-if="page.commercialSiteUrl"
                        class="tw-h-12 3xl:tw-h-16 3xl:tw-w-52 3xl:tw-text-lg"
                        color="blue"
                        rounded="pill"
                        variant="outlined">
                        <a
                            :href="page.commercialSiteUrl"
                            target="_blank"
                            >Site comercial</a
                        >
                    </v-btn>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import Tag from '@/components/Tag.vue';
    import NewsSlider from '@/components/NewsSlider.vue';
    import PartnersSlider from '@/components/PartnersSlider.vue';
    import { useAppStore } from '../../store/app';
    import { useMedia } from '../../composables/useMedia';
    import Loader from '@/components/Loader.vue';

    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();

    const page = computed(() => {
        return appStore.getProductBySlug($route.params.slug as string);
    });

    const pageColor = computed(() => (page.value ? `rgb(var(--v-theme-${page.value.color}))` : ''));

    const bgStyle = computed(() => ({
        backgroundImage: `url(${useMedia(page.value.bannerImage).original})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }));

    const hasFeatures = computed(() => page.value?.features?.length > 0 || page.value.featuresText);

    const hasCharacteristics = computed(() => page.value?.characteristics?.length > 0 || page.value.characteristicsText);

    watchEffect(() => {
        if (!page.value && appStore.hasFinishedLoading) {
            $router.push({ name: 'NotFound' });
        }
    });

    watchEffect(() => {
        // set the css variable --page-color
        if (pageColor.value) {
            document.documentElement.style.setProperty('--page-color', pageColor.value);
        }
    });
</script>

<style lang="scss">
    .banner-btn.v-btn--variant-outlined {
        border-width: 2px !important;
    }
</style>

<style lang="scss" scoped>
    .bottom-banner {
        @apply tw-relative;

        &:before {
            background: transparent linear-gradient(259deg, #00a8c9 0%, #2d5fa9 100%) 0% 0% no-repeat padding-box;
            content: '';
            position: absolute;
            inset: 0;
            opacity: 0.22;
        }
    }
</style>
