/**
 * router/index.ts
 *
 */

// Composables
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import _ from 'lodash';

import Home from '@/views/Home.vue';
import Industry from '@/views/Pages/Industry.vue';
import Solution from '@/views/Pages/Solution.vue';
import Product from '@/views/Pages/Product.vue';
import AboutUs from '@/views/About/AboutUs.vue';
import Recognition from '@/views/About/Recognition.vue';
import Identity from '@/views/About/Identity.vue';
import Career from '@/views/Careers/Career.vue';
import ioAcademy from '@/views/Careers/ioAcademy.vue';
import News from '@/views/Pages/News.vue';
import AllNews from '@/views/Pages/AllNews.vue';
import NotFound from '@/views/NotFound.vue';

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home',
    },
    {
        name: 'Industry',
        path: '/industry/:slug',
        component: Industry,
    },
    {
        name: 'Solution',
        path: '/solutions/:slug',
        component: Solution,
    },
    {
        name: 'Product',
        path: '/product/:slug',
        component: Product,
    },
    {
        name: 'AboutUs',
        path: '/about-us',
        component: AboutUs,
    },
    {
        name: 'Recognition',
        path: '/recognition',
        component: Recognition,
    },
    {
        name: 'Identity',
        path: '/identity',
        component: Identity,
    },
    {
        name: 'Career',
        path: '/career',
        component: Career,
    },
    {
        name: 'ioAcademy',
        path: '/io-academy',
        component: ioAcademy,
    },
    {
        name: 'News',
        path: '/last-news',
        component: News,
    },
    {
        name: 'AllNews',
        path: '/all-news',
        component: AllNews,
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
    },
    // 404
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

// on route change it should scroll to top
router.afterEach((to, from) => {
    if (to.name !== from.name || !_.isEqual(to.params, from.params)) {
        window.scrollTo(0, 0);
    }
});

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
    if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
        if (!localStorage.getItem('vuetify:dynamic-reload')) {
            console.log('Reloading page to fix dynamic import error');
            localStorage.setItem('vuetify:dynamic-reload', 'true');
            location.assign(to.fullPath);
        } else {
            console.error('Dynamic import error, reloading page did not fix it', err);
        }
    } else {
        console.error(err);
    }
});

router.isReady().then(() => {
    localStorage.removeItem('vuetify:dynamic-reload');
});

export default router;
