<template>
    <template v-if="page">
        <div class="min-h-dvh tw-relative tw-flex tw-flex-col tw-overflow-hidden">
            <div class="bg-grad tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-10 2xl:tw-right-1/2"></div>
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-grid tw-flex-grow tw-grid-cols-1 tw-gap-16 tw-py-8 xl:tw-grid-cols-12 3xl:tw-pt-16">
                <div
                    class="tw-relative tw-z-30 xl:tw-col-span-6"
                    :class="page.layout === 'right' ? 'tw-order-1' : 'tw-order-2'">
                    <Tag
                        :color="page.color"
                        :text="page.name" />
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="tw-relative tw-left-1/2 tw-my-8 tw-max-h-96 tw-max-w-[calc(100%+100px)] -tw-translate-x-1/2 xl:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                    <h1
                        class="tw-mt-6"
                        v-html="page.bannerTitle"></h1>
                    <div
                        class="h3 ckeditor tw-mt-3 md:tw-mt-6 lg:tw-mt-8"
                        v-html="page.bannerDescription"></div>
                </div>
                <div
                    class="tw-relative max-xl:tw-hidden xl:tw-col-span-6"
                    :class="page.layout === 'right' ? 'tw-order-2' : 'tw-order-1'">
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="desktop-image tw-absolute tw-inset-0 tw-z-10 tw-mx-auto tw-h-full tw-object-contain max-md:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                </div>
            </div>
        </div>
        <div class="io-container radial-grad">
            <h2
                class=""
                v-html="page.contentTitle"></h2>
            <v-expansion-panels
                v-if="page.expansionPanels?.length"
                class="solution-panels">
                <v-expansion-panel
                    v-for="panel in page.expansionPanels"
                    :key="panel.id"
                    elevation="0">
                    <v-expansion-panel-title
                        class="tw-text-lg tw-font-semibold xl:tw-text-2xl 3xl:tw-text-3xl"
                        :collapse-icon="collapseClose"
                        :expand-icon="collapseOpen">
                        <div v-html="panel.title"></div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="xl:tw-text-lg 3xl:tw-text-xl">
                        <div
                            class="ckeditor"
                            v-html="panel.text"></div>
                    </v-expansion-panel-text>
                    <v-divider
                        class="tw-my-2 3xl:tw-my-8"
                        color="dark"
                        opacity="1"
                        thickness="1.5"></v-divider>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div
            v-if="page.products?.length"
            class="io-container">
            <h2
                v-if="page.productsTitle"
                v-html="page.productsTitle"></h2>
            <h2
                v-else
                class="">
                Os nossos <span class="tw-text-blue">produtos</span>
            </h2>
            <ProductsGrid :products="page.products" />
        </div>
        <div
            v-if="page.projects?.length"
            class="io-container">
            <h2
                v-if="page.projectsTitle"
                v-html="page.projectsTitle"></h2>
            <h2
                v-else
                class="">
                <span class="tw-text-blue">Projetos</span> relacionados
            </h2>
            <ProjectsSlider :projects="page.projects" />
        </div>

        <div class="bottom-banner tw-py-20">
            <div class="io-container tw-relative tw-my-0">
                <h2
                    class="max-md:tw-text-2xl sm:tw-w-8/12"
                    v-html="page.bottomBannerTitle"></h2>
                <v-btn
                    class="tw-h-12 3xl:tw-h-16 3xl:tw-w-52 3xl:tw-text-lg"
                    color="blue"
                    rounded="pill"
                    >Contacte-nos</v-btn
                >
            </div>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import ProductsGrid from '@/components/ProductsGrid.vue';
    import collapseClose from '@/components/icons/collapse-close.vue';
    import collapseOpen from '@/components/icons/collapse-open.vue';
    import Tag from '@/components/Tag.vue';
    import { useAppStore } from '@/store/app';
    import { useMedia } from '@/composables/useMedia';

    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();

    const page = computed(() => {
        return appStore.getSolutionBySlug($route.params.slug as string);
    });

    const pageColor = computed(() => (page.value ? `rgb(var(--v-theme-${page.value.color}))` : ''));

    watchEffect(() => {
        if (!page.value && appStore.hasFinishedLoading) {
            $router.push({ name: 'NotFound' });
        }
    });

    watchEffect(() => {
        // set the css variable --page-color
        if (pageColor.value) {
            document.documentElement.style.setProperty('--page-color', pageColor.value);
        }
    });
</script>

<style lang="scss">
    .solution-panels {
        button.v-expansion-panel-title,
        .v-expansion-panel-text__wrapper {
            padding-inline: 0 !important;
        }

        .v-expansion-panel-text__wrapper {
            @apply tw-pt-4;
        }

        .v-expansion-panel::after {
            @apply tw-opacity-0;
        }

        .v-expansion-panel {
            background: unset;
        }
    }
</style>

<style scoped lang="scss">
    .bottom-banner {
        @apply tw-relative;

        & > * {
            @apply tw-z-10;
        }

        &::before {
            @apply tw-absolute tw-inset-0;
            content: '';
            z-index: 1;
            background: url('@/assets/temp/ds.png');
            background-position: center;
            background-size: cover;
        }
    }

    .radial-grad {
        @apply tw-relative;

        & > * {
            @apply tw-z-[1];
        }

        &:before {
            background: transparent radial-gradient(closest-side at 50% 50%, #2d5fa9 0%, #80808000 100%) 0% 0% no-repeat padding-box;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 600px;
            height: 600px;
            transform: translate(-45%, -15%);
            opacity: 0.15;
        }

        &:after {
            background: transparent radial-gradient(closest-side at 50% 50%, v-bind(pageColor) 0%, #80808000 100%) 0% 0% no-repeat padding-box;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 600px;
            height: 600px;
            transform: translate(25%, 25%);
            opacity: 0.15;
        }
    }
</style>
