<template>
    <template v-if="lastNews">
        <div class="io-container tw-mt-28 tw-flex tw-flex-col tw-gap-28">
            <section class="tw-grid tw-grid-cols-1 tw-gap-16 lg:tw-grid-cols-2">
                <img
                    alt="asd"
                    class="tw-aspect-video tw-w-full tw-rounded-3xl tw-object-cover tw-object-center"
                    :src="useMedia(lastNews.image).medium" />
                <div class="tw-flex tw-flex-col tw-gap-4">
                    <h3>{{ parseDate(lastNews.publishedAt) }}</h3>
                    <h1>{{ lastNews.title }}</h1>
                    <div class="h3">{{ lastNews.description }}</div>
                    <p class="h3 tw-mt-auto tw-font-medium tw-text-blue">{{ lastNewsTags }}</p>
                </div>
            </section>
            <section>
                <div class="tw-flex tw-items-center tw-justify-between">
                    <h2 class="tw-m-0">Últimas <span class="tw-text-blue">notícias</span></h2>
                    <v-btn
                        class="h3"
                        color="blue"
                        elevation="0"
                        :to="{ name: 'AllNews' }"
                        variant="text">
                        <span class="tw-mr-3">Ver todas</span>
                        <v-icon>mdi-arrow-top-right</v-icon>
                    </v-btn>
                </div>
                <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-16 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
                    <article
                        v-for="i in news"
                        :key="i.id"
                        class="tw-flex tw-flex-col">
                        <img
                            alt=""
                            class="tw-aspect-square tw-rounded-3xl tw-object-cover tw-object-center"
                            :src="useMedia(i.image).small" />
                        <p class="tw-mt-6 tw-font-normal tw-text-dark">{{ parseDate(i.publishedAt) }}</p>
                        <h4 class="tw-mt-3 tw-text-lg tw-font-semibold lg:tw-text-lg xl:tw-text-xl">{{ i.title }}</h4>
                        <div class="xl:tw-mt-3 xl:tw-text-lg">
                            {{ i.description }}
                        </div>
                        <v-btn
                            class="tw-mt-3 tw-h-10 tw-px-4 max-md:tw-ms-auto md:tw-me-auto xl:tw-mt-6"
                            color="blue"
                            rounded="pill">
                            <a
                                :href="i.url"
                                target="_blank"
                                >Ler mais</a
                            >
                        </v-btn>
                    </article>
                </div>
            </section>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import { useMedia } from '../../composables/useMedia';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import moment from 'moment';
    import Loader from '@/components/Loader.vue';

    const $route = useRoute();

    const selectedTag = ref<string | null>(null);
    const tags = ref([
        {
            id: null,
            name: 'Ver todas',
            slug: null,
        },
    ]);
    const news = ref([]);
    const lastNews = ref<any>(null);

    const lastNewsTags = computed(() => {
        return lastNews.value?.tags.map((tag: any) => tag.name).join(', ');
    });

    function parseDate(date: string) {
        return moment(date).format('DD MMMM, YYYY');
    }

    // fetch last published news
    async function fetchLastNews() {
        try {
            const query = {
                sort: ['publishedAt:desc'],
                populate: ['image', 'tags'],
                pagination: {
                    pageSize: 5,
                    page: 1,
                },
            };
            const { data } = await axios.get(`/news?${qs.stringify(query)}`);
            lastNews.value = data.data[0];
            news.value = data.data.slice(1);
        } catch (error) {
            console.error('Could not fetch last news', error);
        }
    }

    async function init() {
        const query = $route.query;
        if (query.tag) {
            selectedTag.value = query.tag as string;
        }

        fetchLastNews();
    }

    init();
</script>

<style scoped></style>
