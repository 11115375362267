// Utilities
import { defineStore } from 'pinia';
import axios from '@axios';
import qs from 'qs';
import moment from 'moment';
import { useLocale } from 'vuetify';
import { useI18n } from 'vue-i18n';

export const useAppStore = defineStore('app', {
    state: () => ({
        locale: 'pt',
        _isSidebarOpen: false,
        menus: [],
        industries: [],
        solutions: [],
        products: [],
        finishedLoading: false,
    }),
    getters: {
        getLocale(): string {
            return this.locale;
        },
        isSidebarOpen(): boolean {
            return this._isSidebarOpen;
        },
        getMenus(): any[] {
            return this.menus;
        },
        getIndustries(): any[] {
            return this.industries;
        },
        getIndustryBySlug: (state) => (slug: string) => {
            return state.industries.find((industry: any) => industry.slug === slug);
        },
        hasFinishedLoading(): boolean {
            return this.finishedLoading;
        },
        getSolutions(): any[] {
            return this.solutions;
        },
        getSolutionBySlug: (state) => (slug: string) => {
            return state.solutions.find((solution: any) => solution.slug === slug);
        },
        getProducts(): any[] {
            return this.products;
        },
        getProductBySlug: (state) => (slug: string) => {
            return state.products.find((product: any) => product.slug === slug);
        },
    },
    actions: {
        setLocale(locale: string) {
            if(locale.includes('pt')) locale = 'pt';
            else locale = 'en';
            console.log('setLocale', locale);

            this.locale = locale;
            moment.locale(locale);
            const { current } = useLocale();
            current.value = locale;
            const i18n = useI18n();
            i18n.locale.value = locale;
        },
        setIsSidebarOpen(value: boolean) {
            this._isSidebarOpen = value;
        },
        async fillStore() {
            // menus
            try {
                const query = {
                    sort: 'order',
                    filters: {
                        parent: {
                            $null: true,
                        },
                    },
                    populate: {
                        children: {
                            fields: ['documentId', 'title', 'page', 'pageContentSlug'],
                        },
                    },
                    fields: ['documentId', 'title', 'page', 'pageContentSlug'],
                };
                const { data } = await axios.get(`/menus?${qs.stringify(query)}`);
                this.menus = data.data.map((menu: any) => {
                    if (menu.page === 'none') menu.page = null;
                    return {
                        ...menu,
                    };
                });
            } catch (error) {
                console.error('Could not fetch menus', error);
            }

            // industry
            try {
                const query = {
                    populate: ['bannerImage', 'contentImage', 'contentImageIcon', 'contentCards.icon', 'products.logo', 'clients.image', 'projects.image', 'projects.tags'],
                };
                const { data } = await axios.get(`/industries?${qs.stringify(query)}`);
                this.industries = data.data;
            } catch (error) {
                console.error('Could not fetch industries', error);
            }

            // solutions
            try {
                const query = {
                    populate: ['bannerImage', 'expansionPanels', 'products.logo', 'projects.image', 'projects.tags', 'bottomBannerImage'],
                };
                const { data } = await axios.get(`/solutions?${qs.stringify(query)}`);
                this.solutions = data.data;
            } catch (error) {
                console.error('Could not fetch solutions', error);
            }

            // products
            try {
                const query = {
                    populate: ['logo', 'bannerImage', 'bannerButtons', 'contentImage', 'features.icon', 'characteristicsImage', 'characteristics.icon', 'news.image', 'partners.image'],
                };
                const { data } = await axios.get(`/products?${qs.stringify(query)}`);
                this.products = data.data;
            } catch (error) {
                console.error('Could not fetch products', error);
            }

            this.finishedLoading = true;
        },
    },
});
