<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="partner in props.partners"
            :key="partner.documentId">
            <a
                :href="partner.website"
                target="_blank">
                <v-img
                    class="tw-aspect-square tw-w-32 md:tw-w-44 tw-mx-auto"
                    :src="useMedia(partner.image).medium"></v-img>
            </a>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { useMedia } from '@/composables/useMedia';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    const props = defineProps<{
        partners: any[];
    }>();

    const splideOptions = computed(() => ({
        focus: props.partners.length > 6 ? undefined : 'center',
        type: 'slide',
        trimSpace: props.partners.length > 6,
        perPage: 6,
        start: props.partners.length > 6 ? 0 : (props.partners.length - 1) / 2,
        perMove: 1,
        gap: '2rem',
        pagination: false,
        arrows: true,
        autoplay: true,
        interval: 3000,
        breakpoints: {
            1535: {
                perPage: 5,
                gap: '2rem',
            },
            1200: {
                perPage: 3,
                gap: '1rem',
            },
            640: {
                arrows: false,
                pagination: true,
                perPage: 2,
                perMove: 2,
                gap: '1rem',
            },
        },
    }));
</script>

<style scoped></style>
