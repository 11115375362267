<template>
    <div class="bottom-banner bottom-banner-blue-110 tw-py-20">
        <div class="io-container tw-relative">
            <h2
                v-if="props.title"
                v-html="props.title"></h2>
            <h2
                v-else
                class="tw-text-center">
                Os nossos <span class="tw-text-blue">clientes</span>
            </h2>
            <PartnersSlider :partners="props.partners" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import PartnersSlider from './PartnersSlider.vue';

    const props = defineProps<{
        partners: any[];
        title?: string;
    }>();
</script>

<style scoped></style>
