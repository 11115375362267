<template>
    <Splide :options="splideOptions">
        <SplideSlide
            v-for="(project, i) in props.projects"
            :key="project.documentId">
            <div
                class="tw-relative tw-aspect-square tw-overflow-hidden tw-rounded-4xl tw-bg-cover tw-bg-center tw-bg-no-repeat"
                :style="{ backgroundImage: project.image ? `url(${useMedia(project.image).medium})` : '' }">
                <div
                    class="before:tw-to-transparent tw-relative tw-z-[1] tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-end tw-gap-2 tw-px-8 tw-py-7 tw-text-white before:tw-absolute before:tw-inset-0 before:tw-z-[-1] before:tw-bg-gradient-to-t before:tw-from-black before:tw-opacity-70">
                    <div>
                        <span
                            v-for="(tag, k) in project.tags"
                            :key="tag.documentId"
                            class="tw-mr-1 max-md:tw-text-sm"
                            >{{ tag.name }}{{ k == project.tags.length - 1 ? '' : ',' }}</span
                        >
                    </div>
                    <h3>{{ project.name }}</h3>
                </div>
            </div>
        </SplideSlide>
    </Splide>
</template>

<script setup lang="ts">
    import { useMedia } from '@/composables/useMedia';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    const props = defineProps<{
        projects: {
            id: number;
            documentId: string;
            name: string;
            tags: {
                id: number;
                documentId: string;
                name: string;
            }[];
            image: any;
        }[];
    }>();

    const splideOptions = ref({
        type: 'slide',
        perPage: 4,
        perMove: 1,
        gap: '4rem',
        pagination: false,
        arrows: false,
        autoplay: true,
        interval: 3000,
        breakpoints: {
            1535: {
                perPage: 3,
                gap: '2rem',
            },
            1000: {
                perPage: 2,
                gap: '1rem',
            },
            640: {
                perPage: 1,
                gap: '1rem',
            },
        },
    });
</script>

<style scoped></style>
