<template>
    <template v-if="page">
        <div class="min-h-dvh tw-relative tw-flex tw-flex-col tw-overflow-hidden">
            <div class="bg-grad tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-10 2xl:tw-right-1/2"></div>
            <div class="io-container tw-relative tw-z-20 tw-my-0 tw-grid tw-flex-grow tw-grid-cols-1 tw-py-8 max-xl:tw-gap-16 xl:tw-grid-cols-12 3xl:tw-pt-16">
                <div
                    class="tw-relative tw-z-30 xl:tw-col-span-6"
                    :class="page.layout === 'right' ? 'tw-order-1' : 'tw-order-2'">
                    <Tag
                        :color="page.color"
                        :text="page.name" />
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="tw-relative tw-left-1/2 tw-my-8 tw-max-h-96 tw-max-w-[calc(100%+100px)] -tw-translate-x-1/2 xl:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                    <h1
                        class="tw-mt-6"
                        v-html="page.bannerTitle"></h1>
                    <div
                        class="h3 ckeditor tw-mt-3 md:tw-mt-6 lg:tw-mt-8"
                        v-html="page.bannerDescription"></div>
                </div>
                <div
                    class="tw-relative max-xl:tw-hidden xl:tw-col-span-6"
                    :class="page.layout === 'right' ? 'tw-order-2' : 'tw-order-1'">
                    <img
                        v-if="page.bannerImage"
                        alt=""
                        class="desktop-image tw-absolute tw-inset-0 tw-z-10 tw-mx-auto tw-h-full tw-object-contain max-md:tw-hidden"
                        :src="useMedia(page.bannerImage).original" />
                </div>
            </div>
        </div>
        <div class="min-h-dvh io-container tw-flex">
            <div class="tw-my-auto tw-grid tw-grow tw-grid-cols-1 tw-items-center tw-justify-between max-xl:tw-gap-16 xl:tw-grid-cols-[44%_53%]">
                <div
                    class="tw-order-2 tw-h-full"
                    :class="page.layout === 'right' ? 'xl:tw-order-1' : 'xl:tw-order-2'">
                    <div
                        class="connect-square tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-rounded-4xl tw-px-6 tw-py-6 max-md:tw-aspect-square xl:tw-p-16"
                        :style="{ backgroundImage: page.contentImage ? `url(${useMedia(page.contentImage).original})` : '' }">
                        <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
                            <v-btn
                                v-if="page.contentImageKnowMoreUrl"
                                class="tw-h-12 tw-w-40 xl:tw-h-14 xl:tw-w-48 3xl:tw-h-16 3xl:tw-w-52"
                                color="blue"
                                elevation="0"
                                rounded="pill"
                                :to="page.contentImageKnowMoreUrl">
                                <span class="tw-mr-1 tw-text-xs xl:tw-mr-3 xl:tw-text-base 3xl:tw-text-lg">Saiba mais</span>
                                <v-icon class="md:tw-text-xl xl:tw-text-2xl">mdi-arrow-top-right</v-icon>
                            </v-btn>
                            <img
                                v-if="page.contentImageIcon"
                                class="tw-ml-auto tw-aspect-square tw-w-12 xl:tw-w-16"
                                :src="useMedia(page.contentImageIcon).thumbnail" />
                        </div>
                        <div class="tw-mt-16 tw-text-white">
                            <h4
                                class="tw-text-lg tw-font-medium xl:tw-text-2xl 3xl:tw-text-3xl"
                                v-html="page.contentImageTitle"></h4>
                            <p
                                class="tw-mt-3 tw-font-light xl:tw-text-lg 3xl:tw-text-xl"
                                v-html="page.contentImageDescription"></p>
                        </div>
                    </div>
                </div>
                <div
                    class="radial-grad tw-order-1 tw-flex tw-h-full tw-flex-col tw-justify-items-start tw-gap-4"
                    :class="page.layout === 'right' ? 'rg-right xl:tw-order-12' : 'rg-left xl:tw-order-1'">
                    <h2
                        class="tw-mb-4"
                        v-html="page.contentTitle"></h2>
                    <h3
                        class="ckeditor"
                        v-html="page.contentDescription"></h3>

                    <!-- 2 by 2 grid -->
                    <div class="tw-grid tw-grow tw-grid-cols-1 tw-grid-rows-4 tw-gap-4 sm:tw-grid-cols-2 sm:tw-grid-rows-2 3xl:tw-gap-8">
                        <div
                            v-for="card in page.contentCards"
                            :key="card.id"
                            class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-4xl tw-border-[3px] tw-border-blue tw-px-3 tw-py-6 3xl:tw-px-4 3xl:tw-py-10">
                            <img
                                class="tw-h-12 tw-w-12 tw-object-contain 3xl:tw-h-16 3xl:tw-w-16"
                                :class="card.icon ? '' : 'tw-opacity-0'"
                                :src="card.icon ? useMedia(card.icon).thumbnail : undefined" />
                            <h4 class="tw-mt-4 tw-text-center tw-text-xl tw-font-semibold 3xl:tw-text-2xl">{{ card.title }}</h4>
                            <p class="tw-mt-1 tw-text-center tw-font-light max-3xl:tw-text-sm">
                                {{ card.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="page.products?.length"
            class="io-container">
            <h2 v-if="page.productsTitle" v-html="page.productsTitle"></h2>
            <h2 v-else>Os nossos <span class="tw-text-blue">produtos</span></h2>
            <ProductsGrid :products="page.products" />
        </div>
        <div
            v-if="page.projects?.length"
            class="io-container">
            <h2 v-if="page.projectsTitle" v-html="page.projectsTitle"></h2>
            <h2 v-else><span class="tw-text-blue">Projetos</span> relacionados</h2>
            <ProjectsSlider :projects="page.projects" />
        </div>

        <PartnersBanner
            v-if="page.partners?.length"
            :partners="page.partners" />
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import Tag from '@/components/Tag.vue';
    import ProductsGrid from '@/components/ProductsGrid.vue';
    import ProjectsSlider from '@/components/ProjectsSlider.vue';
    import PartnersBanner from '@/components/PartnersBanner.vue';
    import { useAppStore } from '@/store/app';
    import { useMedia } from '@/composables/useMedia';
    import Loader from '@/components/Loader.vue';

    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();

    const page = computed(() => {
        return appStore.getIndustryBySlug($route.params.slug as string);
    });

    const pageColor = computed(() => (page.value ? `rgb(var(--v-theme-${page.value.color}))` : ''));

    watchEffect(() => {
        if (!page.value && appStore.hasFinishedLoading) {
            $router.push({ name: 'NotFound' });
        }
    });

    watchEffect(() => {
        // set the css variable --page-color
        if (pageColor.value) {
            document.documentElement.style.setProperty('--page-color', pageColor.value);
        }
    });
</script>

<style scoped lang="scss">
    .bg-grad {
        background: transparent linear-gradient(133deg, #2d5fa9 0%, #80808000 30%) 0% 0% no-repeat padding-box;
        opacity: 0.25;
    }

    .connect-square {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .radial-grad {
        @apply tw-relative;

        & > * {
            @apply tw-z-[1];
        }

        &:before {
            background: transparent radial-gradient(closest-side at 50% 50%, #2d5fa9 0%, #80808000 100%) 0% 0% no-repeat padding-box;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 600px;
            height: 600px;

            opacity: 0.15;
        }

        &:after {
            background: transparent radial-gradient(closest-side at 50% 50%, #cf4560 0%, #80808000 100%) 0% 0% no-repeat padding-box;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 600px;
            height: 600px;

            opacity: 0.15;
        }

        &.rg-left:before {
            transform: translate(-45%, -15%);
        }

        &.rg-left:after {
            transform: translate(25%, 25%);
        }

        &.rg-right:before {
            transform: translate(45%, -15%);
        }

        &.rg-right:after {
            transform: translate(-15%, 25%);
        }
    }
</style>
