<template>
    <template v-if="hasLoaded">
        <div class="io-container tw-mt-14 tw-flex tw-flex-col tw-gap-28">
            <section>
                <h2>Todas as <span class="tw-text-blue">notícias</span></h2>
                <div class="tw-flex tw-gap-0">
                    <v-btn
                        v-for="tag in tags"
                        :key="tag.id"
                        class="tw-mr-4"
                        color="blue"
                        elevation="0"
                        rounded="pill"
                        :variant="selectedTag == tag.slug ? 'flat' : 'outlined'"
                        @click="selectTag(tag.slug)"
                        >{{ tag.name }}
                    </v-btn>
                    <v-progress-circular
                        v-if="isLoading"
                        class="tw-ml-auto"
                        color="primary"
                        indeterminate></v-progress-circular>
                </div>
                <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-16 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
                    <article
                        v-for="i in news"
                        :key="i.id"
                        class="tw-flex tw-flex-col">
                        <img
                            alt=""
                            class="tw-aspect-square tw-rounded-3xl tw-object-cover tw-object-center"
                            :src="useMedia(i.image).small" />
                        <p class="tw-mt-6 tw-font-normal tw-text-dark">{{ parseDate(i.publishedAt) }}</p>
                        <h4 class="tw-mt-3 tw-text-lg tw-font-semibold lg:tw-text-lg xl:tw-text-xl">{{ i.title }}</h4>
                        <div class="xl:tw-mt-3 xl:tw-text-lg">
                            {{ i.description }}
                        </div>
                        <v-btn
                            class="tw-mt-3 tw-h-10 tw-px-4 max-md:tw-ms-auto md:tw-me-auto xl:tw-mt-6"
                            color="blue"
                            rounded="pill">
                            <a
                                :href="i.url"
                                target="_blank"
                                >Ler mais</a
                            >
                        </v-btn>
                    </article>
                </div>
                <span v-intersect="onPageEnd"></span>
            </section>
        </div>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import { useMedia } from '../../composables/useMedia';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import moment from 'moment';
    import Loader from '@/components/Loader.vue';
    import _ from 'lodash';

    const $router = useRouter();
    const $route = useRoute();

    const selectedTag = ref<string | null>(null);
    const tags = ref([
        {
            id: null,
            name: 'Ver todas',
            slug: null,
        },
    ]);
    const hasLoaded = ref(false);
    const isLoading = ref(false);
    const news = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(0);

    function selectTag(tag: string) {
        selectedTag.value = tag;
        currentPage.value = 1;
        fetchNews();
        $router.push({ query: { tag } });
    }

    function parseDate(date: string) {
        return moment(date).format('DD MMMM, YYYY');
    }

    function onPageEnd(isIntersecting: boolean) {
        console.log('onPageEnd', isIntersecting);

        if (isIntersecting && currentPage.value < totalPages.value) {
            currentPage.value++;
            fetchNews();
        }
    }

    async function fetchNews() {
        isLoading.value = true;
        try {
            const query = {
                sort: ['publishedAt:desc'],
                populate: ['image', 'tags'],
                pagination: {
                    pageSize: 16,
                    page: currentPage.value,
                },
            };

            if (selectedTag.value) {
                _.set(query, 'filters.tags.slug', selectedTag.value);
            }

            const { data } = await axios.get(`/news?${qs.stringify(query)}`);
            if (currentPage.value === 1) {
                news.value = [...data.data];
            } else {
                news.value = [...news.value, ...data.data];
            }

            totalPages.value = data.meta.pagination.pageCount;
        } catch (error) {
            console.error('Could not fetch news', error);
        } finally {
            isLoading.value = false;
        }
    }

    // fetch tags with query params
    async function fetchTags() {
        try {
            const query = {
                sort: ['name:asc'],
                filters: {
                    news: {
                        $notNull: true,
                    },
                },
                pagination: {
                    pageSize: 100,
                },
            };
            const { data } = await axios.get(`/tags?${qs.stringify(query)}`);
            tags.value = [...tags.value, ...data.data];
        } catch (error) {
            console.error('Could not fetch tags', error);
        }
    }

    async function init() {
        const query = $route.query;
        if (query.tag) {
            selectedTag.value = query.tag as string;
        }

        await Promise.all([fetchTags(), fetchNews()]);

        hasLoaded.value = true;
    }

    init();
</script>

<style scoped></style>
