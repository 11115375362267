<template>
    <v-footer
        class="app-footer tw-p-0"
        color="blue">
        <div class="io-container tw-my-0 tw-pb-16 tw-pt-20">
            <v-row>
                <v-col
                    cols="12"
                    sm="6">
                    <v-img
                        class="tw-h-12"
                        position="left"
                        src="@/assets/logo.svg"></v-img>
                </v-col>
                <v-col
                    cols="12"
                    sm="6">
                    <!-- social networks -->
                    <div class="tw-flex tw-justify-between tw-gap-4 max-sm:tw-mt-8 sm:tw-justify-end">
                        <v-btn
                            v-for="(network, key) in socialNetworks"
                            :key="key"
                            class="tw-bg-white tw-bg-opacity-40 tw-text-blue"
                            elevation="0"
                            href="network.url"
                            icon>
                            <v-icon>{{ network.icon }}</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <div class="tw-mt-16 tw-grid tw-grid-cols-1 tw-justify-center tw-gap-6 md:tw-grid-cols-2 xl:tw-flex">
                <div
                    class="tw-relative tw-flex tw-items-center tw-gap-4 tw-px-7 tw-py-4 after:tw-absolute after:tw-inset-0 after:tw-rounded-full after:tw-bg-white after:tw-opacity-10 md:tw-col-span-2">
                    <MarkerIcon class="tw-w-7 tw-min-w-7" />
                    <a
                        class="tw-font-extralight 3xl:tw-text-lg"
                        href="https://maps.app.goo.gl/jXzBgCbLA9tgNiud8"
                        target="_blank">
                        Avenida Dom Afonso Henriques 4694, Vila Nova de Famalicão
                    </a>
                </div>
                <div class="tw-relative tw-flex tw-items-center tw-gap-4 tw-px-7 tw-py-4 after:tw-absolute after:tw-inset-0 after:tw-rounded-full after:tw-bg-white after:tw-opacity-10">
                    <PhoneIcon class="tw-w-7 tw-min-w-7" />
                    <a
                        class="tw-text-lg tw-font-extralight"
                        href="tel:+351252116943"
                        >+351 252 116 943</a
                    >
                </div>
                <div class="tw-relative tw-flex tw-items-center tw-gap-4 tw-px-7 tw-py-4 after:tw-absolute after:tw-inset-0 after:tw-rounded-full after:tw-bg-white after:tw-opacity-10">
                    <EmailIcon class="tw-w-7 tw-min-w-7" />
                    <a
                        class="tw-text-lg tw-font-extralight"
                        href="mailto:info@iotech.pt"
                        >info@iotech.pt</a
                    >
                </div>
            </div>
        </div>
    </v-footer>
    <v-footer
        class="tw-p-0"
        color="blue">
        <div class="io-container tw-my-0 tw-grid tw-grid-cols-2 tw-gap-4 tw-py-4 tw-font-thin tw-opacity-60 max-md:tw-text-center md:tw-flex lg:tw-gap-12">
            <p class="tw-col-span-2">@ {{ currentYear }} Copyrights by IOTech. All Rights Reserved.</p>
            <router-link class="md:tw-ml-auto md:tw-text-center">Termos e condições</router-link>
            <router-link class="md:tw-text-center">Política de privacidade</router-link>
        </div>
    </v-footer>
</template>

<script setup lang="ts">
    import MarkerIcon from '@/components/icons/marker.vue';
    import PhoneIcon from '@/components/icons/phone.vue';
    import EmailIcon from '@/components/icons/email.vue';

    const currentYear = new Date().getFullYear();

    const socialNetworks = [
        {
            icon: 'mdi-facebook',
            title: 'Facebook',
            url: 'https://facebook.com',
        },
        {
            icon: 'mdi-youtube',
            title: 'Youtube',
            url: 'https://youtube.com',
        },
        {
            icon: 'mdi-linkedin',
            title: 'LinkedIn',
            url: 'https://linkedin.com',
        },
        {
            icon: 'mdi-instagram',
            title: 'Instagram',
            url: 'https://instagram.com',
        },
    ];
</script>

<style lang="scss">
    .app-footer {
        position: relative;
        & * {
            z-index: 1;
        }
        &::after {
            @apply tw-absolute tw-inset-0;
            content: '';
            background-position: 0% 0% !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-image: url('@/assets/background.png') !important;
            filter: opacity(0.5);
        }
    }
</style>
